import heroAnimation from '../animations/hero-animation.js'
import contentAnimation from '../animations/content-animation.js'
import featureIconListAnimation from '../animations/feature-icon-list-animation.js'
import linkHolderCardsAnimation from '../animations/link-holder-cards-animation.js'
import basicSliderAnimation from '../animations/basic-slider-animation.js'
import testimonialsAnimation from '../animations/testimonials-animation.js'
import productListingAnimation from '../animations/product-listing-animation.js'
import searchHeroAnimation from '../animations/search-hero-animation.js'
import resourceListAnimation from '../animations/resource-list-animation.js'
import statShowcaseAnimation from '../animations/stat-showcase-animation.js'
import partnersAnimation from '../animations/partners-animation.js'
import imageContentAnimation from '../animations/image-content-animation.js'
import awardsAnimation from '../animations/awards-animation.js'
import heroContentAnimation from '../animations/hero-content-animation.js'
import offsetImageContentAnimation from '../animations/offset-image-content-animation.js'
import productHeroAnimation from '../animations/product-hero-animation.js'
import productContentAnimation from "../animations/product-content-animation.js";
import howItWorksAnimation from "../animations/how-it-works-animation.js";
import benefitsAnimation from "../animations/benefits-animation.js";
import applicationsAnimation from "../animations/applications-animation.js";
import configurationsAnimation from "../animations/configurations-animation.js";
import featureHeroAnimation from "../animations/feature-hero-animation.js";
import listingsAnimation from "../animations/listings-animation.js";
import pillarsAnimation from "../animations/pillars-animation.js";
import logoWallAnimation from "../animations/logo-wall-animation.js";
import quoteWallAnimation from "../animations/quote-wall-animation.js";
import productStatisticsAnimation from "../animations/product-statistics-animation.js";
import productVideosAnimation from "../animations/product-videos-animation.js";

function init() {

    // Home page
    // heroAnimation()
    contentAnimation()
    featureIconListAnimation()
    linkHolderCardsAnimation()
    basicSliderAnimation()
    testimonialsAnimation()
    // Product overview page
    productListingAnimation()
    // Resources page
    searchHeroAnimation()
    resourceListAnimation()
    // About page
    statShowcaseAnimation()
    partnersAnimation()
    imageContentAnimation()
    awardsAnimation()
    heroContentAnimation()
    offsetImageContentAnimation()
    // Product page
    productHeroAnimation()
    productContentAnimation()
    productStatisticsAnimation()
    howItWorksAnimation()
    benefitsAnimation()
    applicationsAnimation()
    configurationsAnimation()
    // Feature hero
    featureHeroAnimation()
    // Listings
    listingsAnimation()
    // Sustainability
    pillarsAnimation()
    logoWallAnimation()
    quoteWallAnimation()
    productVideosAnimation()
}

window.addEventListener('load', init);
