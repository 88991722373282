import { tns } from "tiny-slider";

function init() {

    const how = document.querySelector('.how-it-works')

    if (!how) return

    const container = how.querySelector('.how-it-works__image-container')
    const navContainer = how.querySelector('.how-it-works__list')

    if (Array.from(container.children).length > 1) {
        tns({
            container,
            controls: false,
            navContainer,
            mode: "gallery"
        })
    }

}

init();
