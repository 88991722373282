
function init() {

    const trigger = document.querySelector('.hero-drawer-trigger')
    const drawer = document.querySelector('.hero-drawer')

    if (!trigger || !drawer) return;

    const ACTIVE_CLASS = 'hero-drawer--active'

    trigger.addEventListener('click', () => {
        setTimeout(() => {
            drawer.classList.add(ACTIVE_CLASS)
        })
    })

    window.addEventListener('click', (e) => {
        if (!drawer.contains(e.target)) {
            drawer.classList.remove(ACTIVE_CLASS)
        }
    })
}

init()
