import { gsap } from "gsap";


function setup(hero) {
    let items = Array.from(hero.querySelectorAll('.prefix-title span'))
    const card = hero.querySelector('.feature-hero__card')

    items = items.map(item => {
        item.innerHTML = `<span>${item.innerHTML}</span>`
        return item.querySelector('span')
    })

    const tl = gsap.timeline()

    tl.from(items, {
        skewY: '4deg',
        translateY: '60px',
        transformOrigin: 'top left',
        delay: 0.5,
        stagger: 0.1,
        opacity: 0,
        ease: "elastic.out(1, 1)"
    })

    tl.from(card, {
        translateY: '60px',
        opacity: 0,
        ease: "elastic.out(1, 1)"
    }, "-=50%")

}

export default () => {
    const heros = Array.from(document.querySelectorAll('.feature-hero'))

    if (!heros) return;

    heros.forEach(setup)
}
