import { gsap } from "gsap";

function setup(container) {

    const items = Array.from(container.querySelectorAll('.feature-icon'))

    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: container,
            start: 'top 75%',
            end: 'bottom top',
            // markers: true
        }
    })

    tl.addLabel("start")

    items.forEach((item, i) => {
        const children = item.children
        tl.from(children, {
            delay: i / 5,
            opacity: 0,
            translateY: '10px',
            stagger: 0.1
        }, "start")
    })
}

export default () => {
    const els = Array.from(document.querySelectorAll('.feature-icon-list'))

    if (!els) return;

    els.forEach(setup)
}
