import { gsap } from "gsap";


function setup(container) {
    let items = Array.from(container.querySelectorAll('.pillars__prefix, .pillars__title'))
    const content = container.querySelector('.pillars__titlebar-container .content')

    items = items.map(item => {
        item.innerHTML = `<span>${item.innerHTML}</span>`
        return item.querySelector('span')
    })

    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: container,
            start: 'top 75%',
            end: 'bottom top',
            //markers: true
        }
    })

    tl.from(items, {
        display: 'block',
        skewY: '4deg',
        translateY: '60px',
        transformOrigin: 'top left',
        delay: 0.5,
        stagger: 0.1,
        opacity: 0,
        ease: "elastic.out(1, 1)"
    })

    tl.from(content, {
        opacity: 0
    }, "-=50%")

    const bg = container.querySelector('.pillars-circle__background')

    tl.from(bg, {
        opacity: 0,
        translateY: '-20px',
        translateX: '20px',
        duration: .1
    }, "<")

    const list = container.querySelectorAll('.pillar')

    tl.from(list, {
        opacity: 0,
        translateY: '10px',
        stagger: 0.05,
        ease: "elastic.out(1, 1)"
    }, '<')

}

export default () => {
    const heros = Array.from(document.querySelectorAll('.pillars'))

    if (!heros) return;

    heros.forEach(setup)
}
