import { tns } from "tiny-slider"

function setup(timeline) {
    const container = timeline.querySelector('.timeline-item__wrapper')
    const navContainer = timeline.querySelector('.timeline__dates-wrapper')
    const controlsContainer = timeline.querySelector('.timeline__controls')
    const dates = timeline.querySelector('.timeline__navigation')

    const slider = tns({
        container,
        navContainer,
        controlsContainer,
        autoHeight: false,
        gutter: 140
    })

    slider.events.on('indexChanged', () => {
        const { displayIndex } = slider.getInfo()
        const index = displayIndex - 1
        const small = 50
        const margin = 32
        const offset = small + margin

        dates.style.translate = `-${offset * index}px 0`

    })
}

function init() {
    const timelines = Array.from(document.querySelectorAll('.timeline'))

    if (!timelines.length) return

    timelines.forEach(setup)
}

init()
