window.addEventListener('load', init)

function init() {
    // insert videos after dom loads
    let videos = document.querySelectorAll('.vimeo-video')
    if (videos.length) {
        videos.forEach((el) => {
            let src = el.dataset.src;
            if(src) {
                el.src = src;
            }
        })
    }
}


/*import { MediaPlayer } from 'dashjs';

window.addEventListener('load', init)

function init() {
    let videos = document.querySelectorAll('.vimeo-video')
    if (videos.length) {
        // start/stop vimeo videos as they enter/exit the viewport
        videos.forEach((el) => {
            let src = el.dataset.src;
            if(src) {
                let player = MediaPlayer().create();
                player.initialize(el, src, true);
            }
        })
    }
}*/


/*
import Player from '@vimeo/player'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { gsap } from 'gsap'

gsap.registerPlugin(ScrollTrigger)

window.addEventListener('load', init)

function init() {
    let videos = document.querySelectorAll('.trigger-video')
    if (videos.length) {
        // start/stop vimeo videos as they enter/exit the viewport
        videos.forEach((el) => {
            let player = new Player(el)

            player.ready().then(function () {
                ScrollTrigger.create({
                    trigger: el,
                    start: 'top bottom',
                    end: 'bottom top',
                    markers: false,
                    onEnter: () => {
                        player.getPaused().then(function(paused) {
                            player.play()
                        })
                    },
                    onEnterBack: () => {
                        player.getPaused().then(function(paused) {
                            player.play()
                        })
                    },
                    onLeave: () => {
                        player.pause()
                    },
                    onLeaveBack: () => {
                        player.pause()
                    },
                })
            })
        })
    }
}
*/
