import { gsap } from "gsap";

import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

function getSectionFromHref(href) {
    const split = href.split('/')
    const id = split[split.length - 1]
    return document.querySelector(id);
}

function getLinkFromID(links, id) {
    return links.find(link => {
        const split = link.getAttribute('href').split('/')
        const _id = split[split.length - 1]
        return '#'+id === _id
    })
}

function init() {

    const nav = document.querySelector('.product-nav')

    if (!nav) return

    const links = Array.from(nav.querySelectorAll('.product-nav__menu a'))

    if (!links.length) return

    const sections = links.map(link => {
        return getSectionFromHref(link.getAttribute('href'))
    })

    sections.forEach(section => {
        ScrollTrigger.create({
            trigger: section,
            start: "top center",
            end: "bottom center",
            // markers: true,
            onEnterBack: () => {
                const link = getLinkFromID(links, section.getAttribute('id'))
                link.classList.add('active')
            },
            onEnter: () => {
                const link = getLinkFromID(links, section.getAttribute('id'))
                link.classList.add('active')
            },
            onLeaveBack: () => {
                const link = getLinkFromID(links, section.getAttribute('id'))
                link.classList.remove('active')
            },
            onLeave: () => {
                const link = getLinkFromID(links, section.getAttribute('id'))
                link.classList.remove('active')
            }
        });
    })
}

init()
