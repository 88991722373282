import { gsap } from "gsap";

function setup(content) {

    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: content,
            start: 'top 75%',
            end: 'bottom top',
            // markers: true
        }
    })

    tl.from(content, {
        opacity: 0,
    })
}

export default () => {
    const els = Array.from(document.querySelectorAll('.content-section'))

    if (!els) return;

    els.forEach(setup)
}
