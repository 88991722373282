function setup(accordion) {
    const details = Array.from(accordion.querySelectorAll('details'))

    details.forEach(detail => {
        detail.open = true
        const content = detail.querySelector('.accordion-item__content')
        const { offsetHeight } = content

        detail.style.setProperty('--max-height', `${offsetHeight}px`)
        detail.open = false
    })
}

function init() {
    const accordions = Array.from(document.querySelectorAll('.accordion'))

    if (!accordions.length) return;

    accordions.forEach(setup)
}

init();
