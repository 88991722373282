import { gsap } from "gsap";


function setup(container) {
    let items = container.children

    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: container,
            start: 'top 75%',
            end: 'bottom top',
            //markers: true
        }
    })

    tl.from(items, {
        translateY: '10%',
        opacity: 0,
        ease: "elastic.out(1, 1)",
        stagger: 0.1,
    })
}

export default () => {
    const heros = Array.from(document.querySelectorAll('.listings__list'))

    if (!heros) return;

    heros.forEach(setup)
}
