import submitAjaxForm from "../utils/submitAjaxForm"

function init() {

    const finder = document.querySelector('.resource-finder')

    if(!finder) return

    const form = finder.querySelector('form')
    const container = '.resource-finder__container .resource-finder__main'

    const categories = Array.from(form.querySelectorAll('.resource-finder__sidebar-categories input'))

    categories.forEach(category => {
        category.addEventListener('change', () => {
            // Clear the default active class on the "All Files" link
            let active = form.querySelector('.active')
            if (active !==  null) {
                active.classList.remove('active')
            }

            submitAjaxForm(form, container)
            // form.submit()
        })
    })

    const products = Array.from(form.querySelectorAll('.resource-finder__sidebar-block-container input'))

    products.forEach(product => {
        product.addEventListener('change', () => {
            submitAjaxForm(form, container)
            // form.submit()
        })
    })
}

init()
