import { gsap } from "gsap";

function setup(container) {

    const links = container.querySelectorAll('.resource-card')

    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: container,
            start: '-100px 75%',
            end: 'bottom top',
            // markers: true
        }
    })

    tl.from(links, {
        opacity: 0,
        translateY: '10px',
        stagger: 0.1,
        ease: "elastic.out(1, 1)"
    })

}

export default () => {
    const els = Array.from(document.querySelectorAll('.resource-finder__container'))

    if (!els) return;

    els.forEach(setup)
}
