window.addEventListener('load', init)

function init() {
    const videoSection = document.querySelector('.video-player')
    const wrapper = document.querySelector('.playlist-wrapper')
    const items = document.querySelectorAll('.playlist-item')

    if (!videoSection || !wrapper || !items) {
        return false
    }

    const container = wrapper.querySelector('.playlist-wrapper-container')
    const iframe = videoSection.querySelector('iframe')

    let width = 200

    window.addEventListener('resize', () => {
        // if (window.innerWidth < 1279) {
        //     container.style.width =
        //         width * (Array.from(items).length + 2) + 'px'
        // } else {
        //     container.style.width = 'auto'
        // }
    })

    console.log(items)

    Array.from(items).forEach((item) => {
        item.addEventListener('click', () => {
            const content = item.querySelector('.wysiwyg')
            Array.from(items).forEach((item) => {
                item.classList.remove('active')
            })
            item.classList.add('active')
            const src = item.dataset.src
            iframe.setAttribute('src', src)
        })
    })
}

