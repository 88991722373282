import submitAjaxForm from "../utils/submitAjaxForm"
import attachQueryParamsToForm from "../utils/attachQueryParamsToForm";
import getURLParams from "../utils/getURLParams";

function addScrollEvents() {
    let scrollLinks = Array.from(document.querySelectorAll('[data-scroll-to]'))
    if (!scrollLinks.length) {
        return;
    }
    scrollLinks.forEach(function (link) {
        link.addEventListener('click', function (e) {
            let linkDestination = link.dataset.scrollTo;
            e.preventDefault()
            document.querySelector('#'+linkDestination).scrollIntoView()
        })
    })
}

function init() {
    const form = document.querySelector('.search-hero__form form')

    addScrollEvents()

    if (!form) return;

    form.addEventListener('submit', (e) => {
        e.preventDefault()

        const params = getURLParams()

        // remove the query in the URL since we will use the one in the form
        if (params.q) {
            delete params.q
        }

        attachQueryParamsToForm(form, params)

        submitAjaxForm(form, form.dataset.container, function () {
            addScrollEvents()
        })
        // form.submit()


    })
}

init()
