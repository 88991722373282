// This isn't doing anything right now - just an idea
function setup(element) {
    const canvas = element
    const ctx = canvas.getContext('2d');
    const video = document.createElement('video');

    // Set the video source and attributes
    video.src = element.getAttribute('src');
    video.autoplay = true;
    video.loop = true;

    // When the video is loaded, draw it onto the canvas
    video.addEventListener('loadedmetadata', function() {
        canvas.width = canvas.parentNode.clientWidth; // Set canvas width to match parent container width
        canvas.height = video.videoHeight * (canvas.width / video.videoWidth); // Calculate canvas height based on video aspect ratio
        drawVideo();
    });

    // Function to continuously draw video frames onto the canvas
    function drawVideo() {
        if (video.paused || video.ended) return;
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        requestAnimationFrame(drawVideo);
    }
}

function init() {
    const videos = Array.from(document.querySelectorAll('canvas.video'));
    if (!videos.length) return;

    videos.forEach(setup)
}

init();
