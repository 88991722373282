import { gsap } from "gsap";

function setup(container) {
    const title = container.querySelectorAll('.product-section__title')

    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: container,
            start: 'top 75%',
            end: 'bottom top',
            // markers: true
        }
    })

    tl.from(title, {
        opacity: 0,
        translateY: '-10%',
        ease: "elastic.out(1, 1)",
    })

    const list = container.querySelectorAll('.how-it-works__inner')
    const main = container.querySelectorAll('.how-it-works__main')

    tl.from([...list, ...main], {
        opacity: 0,
        translateX: '10%',
        ease: "elastic.out(1, 1)",
        stagger: 0.05
    })

}

export default () => {
    const els = Array.from(document.querySelectorAll('.how-it-works'))

    if (!els) return;

    els.forEach(setup)
}
