import { gsap } from "gsap";

function setup(container) {
    const card = container.querySelector('.product-hero__card')

    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: container,
            start: 'top 75%',
            end: 'bottom top',
            // markers: true
        }
    })

    tl.from(card, {
        opacity: 0,
        delay: .5,
        translateX: '-10%',
        ease: "elastic.out(1, 1)"
    })

    const categories = card.querySelectorAll('.product-hero__categories a, product-hero__categories span')

    tl.from(categories, {
        opacity: 0,
        translateX: '10%',
        ease: "elastic.out(1, 1)",
        stagger: 0.05
    })

    const items = card.querySelectorAll('h1, p, .product-hero__actions')

    tl.from(items, {
        opacity: 0,
        translateY: '10%',
        ease: "elastic.out(1, 1)",
        stagger: 0.05
    }, "<")

}

export default () => {
    const els = Array.from(document.querySelectorAll('.product-hero'))

    if (!els) return;

    els.forEach(setup)
}
