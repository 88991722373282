import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";

function setup(container) {
    const content = container.querySelector('.image-content__content')
    const image = container.querySelectorAll('.image-content__image img')

    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: container,
            start: 'top 75%',
            end: 'bottom top',
        }
    })

    tl.from(content, {
        translateX: '60px',
        opacity: 0,
        ease: "elastic.out(1, 1)"
    })

    tl.from(image, {
        translateX: '-60px',
        opacity: 0,
        ease: "elastic.out(1, 1)"
    }, "<")
}

export default () => {
    const els = Array.from(document.querySelectorAll('.image-content'))

    if (!els) return;

    els.forEach(setup)
}
