export default function submitAjaxForm(form, selector, callback = null) {
    const formData = new FormData(form)
    const queryString = new URLSearchParams(formData).toString();
    const element = document.querySelector(selector)
    const LOADING_CLASS = 'filter-loading'
    element.classList.add(LOADING_CLASS)

    const headers = new Headers();
    headers.append('X-Requested-With', 'XMLHttpRequest');

    const url = window.location.origin + window.location.pathname + '?' + queryString

    fetch(url, {
            method: 'GET',
            headers,
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('There was a problem with the request.');
            }
            element.classList.remove(LOADING_CLASS)
            return response.text();
        })
        .then(data => {
            element.outerHTML = data
            element.classList.remove(LOADING_CLASS)
            if (callback) {
                callback()
            }
        })
        .catch(error => {
            console.error(error);
        });
}
