import { gsap } from "gsap";

function setup(container) {
    const items = Array.from(container.querySelectorAll('.product-statistic'))

    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: container,
            start: 'top 75%',
            end: 'bottom top',
            //markers: true
        }
    })

    tl.from(items, {
        opacity: 0,
        translateY: '10px',
        stagger: 0.05
    })

    items.forEach((item, i) => {
        const children = item.children

        tl.from(children, {
            delay: i / 10,
            opacity: 0,
            translateY: '10px',
            stagger: 0.1
        }, "<")
    })
}

export default () => {
    const els = Array.from(document.querySelectorAll('.product-statistics'))

    if (!els) return;

    els.forEach(setup)
}
