import { gsap } from "gsap";

function setup(container) {
    const image = container.querySelectorAll('.offset-image-content__image-wrapper')

    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: container,
            start: 'top 75%',
            end: 'bottom top',
        }
    })

    tl.from(image, {
        translateX: '-50%',
        opacity: 0,
        ease: "elastic.out(1, 1)"
    })
}

export default () => {
    const els = Array.from(document.querySelectorAll('.offset-image-content'))

    if (!els) return;

    els.forEach(setup)
}
