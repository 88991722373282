import { gsap } from "gsap";

function setup(container) {
    const title = container.querySelectorAll('.product-section__title')

    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: container,
            start: 'top 75%',
            end: 'bottom top',
            // markers: true
        }
    })

    tl.from(title, {
        opacity: 0,
        translateY: '-10%',
        ease: "elastic.out(1, 1)",
    })

    const blocks = Array.from(container.querySelectorAll('.content-wall__block'))

    blocks.forEach(block => {
        const image = block.querySelector('.content-wall__image')
        const content = block.querySelector('.content-wall__content')

        const tl2 = gsap.timeline({
            scrollTrigger: {
                trigger: block,
                start: '-50% 50%',
                end: 'bottom top',
                // markers: true
            }
        })

        tl2.from(image, {
            opacity: 0,
            translateX: '-10%',
            ease: "elastic.out(1, 1)",
        })
        tl2.from(content, {
            opacity: 0,
            translateX: '10%',
            ease: "elastic.out(1, 1)",
        }, "<")
    })

}

export default () => {
    const els = Array.from(document.querySelectorAll('.content-wall'))

    if (!els) return;

    els.forEach(setup)
}
