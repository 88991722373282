import { gsap } from "gsap";


function setup(container) {
    let items = Array.from(container.querySelectorAll('.logo-wall__prefix, .logo-wall__title'))
    const content = container.querySelector('.logo-wall__content')

    items = items.map(item => {
        item.innerHTML = `<span>${item.innerHTML}</span>`
        return item.querySelector('span')
    })

    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: container,
            start: 'top 75%',
            end: 'bottom top',
            // markers: true
        }
    })

    tl.from(items, {
        display: 'block',
        skewY: '4deg',
        translateY: '60px',
        transformOrigin: 'top left',
        stagger: 0.1,
        opacity: 0,
        ease: "elastic.out(1, 1)"
    })

    tl.from(content, {
        opacity: 0
    }, "-=50%")

    const list = container.querySelectorAll('.logo-wall__logo')

    tl.from(list, {
        opacity: 0,
        translateY: '10px',
        stagger: 0.05,
        ease: "elastic.out(1, 1)"
    }, '<')

}

export default () => {
    const heros = Array.from(document.querySelectorAll('.logo-wall'))

    if (!heros) return;

    heros.forEach(setup)
}
