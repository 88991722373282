import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";

function setup(container) {
    let title = Array.from(container.querySelectorAll('.awards__titlebar'))
    const content = container.querySelector('.awards__content')
    const items = container.querySelectorAll('.award')

    if (title.length) {
        title = title.map(item => {
            gsap.set(item, { overflow: 'hidden' })
            item.innerHTML = `<span>${item.innerHTML}</span>`
            return item.querySelector('span')
        })
    }

    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: container,
            start: 'top 75%',
            end: 'bottom top',
            // markers: true
        }
    })

    if (title.length) {
        tl.from(title, {
            display: 'block',
            skewY: '4deg',
            translateY: '60px',
            transformOrigin: 'top left',
            delay: 0.5,
            stagger: 0.1,
            opacity: 0,
            ease: "elastic.out(1, 1)"
        })
    }

    tl.from(content, {
        opacity: 0
    }, '-=50%')

    tl.from(items, {
        skewY: '4deg',
        translateY: '60px',
        transformOrigin: 'top left',
        stagger: 0.05,
        opacity: 0,
        ease: "elastic.out(1, 1)"
    })
}

export default () => {
    const els = Array.from(document.querySelectorAll('.awards'))

    if (!els) return;

    els.forEach(setup)
}
