import { gsap } from "gsap";

function setup(container) {
    const content = container.querySelector('.testimonials__container')
    const icons = container.querySelectorAll('.testimonials__testimonial-logos > div')

    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: container,
            start: 'top 75%',
            end: 'bottom top',
            // markers: true
        }
    })

    tl.from(content, {
        opacity: 0,
        translateY: '20px',
        ease: "elastic.out(1, 1)"
    })

    if (icons) {
        tl.from(icons, {
            opacity: 0,
            translateY: '20px',
            ease: "elastic.out(1, 1)",
            stagger: 0.075
        })
    }
}

export default () => {
    const els = Array.from(document.querySelectorAll('.testimonials'))

    if (!els) return;

    els.forEach(setup)
}
