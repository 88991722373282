import { gsap } from "gsap";

import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

function getLinkFromID(links, id) {
    return links.find(link => {
        const group = link.dataset.faqGroup
        return id === group
    })
}

function init() {

    const nav = document.querySelector('.faq-content__sidebar')

    if (!nav) return

    const links = Array.from(nav.querySelectorAll('.faq-content__sidebar-list a'))

    if (!links.length) return

    const sections = document.querySelectorAll('.faq-content__groups .faq-group')

    sections.forEach(section => {
        ScrollTrigger.create({
            trigger: section,
            start: "top center",
            end: "bottom center",
            // markers: true,
            onEnterBack: () => {
                const link = getLinkFromID(links, section.getAttribute('id'))
                link.classList.add('active')
            },
            onEnter: () => {
                const link = getLinkFromID(links, section.getAttribute('id'))
                link.classList.add('active')
            },
            onLeaveBack: () => {
                const link = getLinkFromID(links, section.getAttribute('id'))
                link.classList.remove('active')
            },
            onLeave: () => {
                const link = getLinkFromID(links, section.getAttribute('id'))
                link.classList.remove('active')
            }
        });
    })
}

init()
