import { gsap } from "gsap";


function setup(container) {

    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: container,
            start: '-50% 75%',
            end: 'center center',
            scrub: 1
            //markers: true
        }
    })

    const bg = container.querySelector('.quote-wall__background')
    const left = container.querySelector('.quote-wall__left')
    const right = container.querySelector('.quote-wall__right')

    tl.from(bg, {
        opacity: 0,
    })

    tl.from(left, {
        opacity: 0,
    }, "-=50%")

    tl.from(right, {
        opacity: 0,
    }, "-=50%")
}

export default () => {
    const heros = Array.from(document.querySelectorAll('.quote-wall'))

    if (!heros) return;

    heros.forEach(setup)
}
