import { gsap } from "gsap";

function setup(container) {
    let title = Array.from(container.querySelectorAll('.prefix-title span'))
    const actions = container.querySelectorAll('.section-titlebar__actions a')
    const slides = container.querySelectorAll('.basic-slider__image-slides .tns-slide-active')
    const controls = container.querySelectorAll('.basic-slider__control-container')

    if (title.length) {
        title = title.map(item => {
            gsap.set(item, { overflow: 'hidden' })
            item.innerHTML = `<span>${item.innerHTML}</span>`
            return item.querySelector('span')
        })
    }

    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: container,
            start: 'top 80%',
            end: 'bottom top',
            // markers: true
        }
    })

    if (title.length) {
        tl.from(title, {
            display: 'block',
            skewY: '4deg',
            translateY: '60px',
            transformOrigin: 'top left',
            delay: 0.5,
            stagger: 0.1,
            opacity: 0,
            ease: "elastic.out(1, 1)"
        })
    }

    if (actions) {
        tl.from(actions, {
            opacity: 0,
            translateY: '10px',
            ease: "elastic.out(1, 1)"
        }, "-=50%")
    }

    if (slides) {
        tl.from(slides, {
            opacity: 0,
            translateX: '20px',
            ease: "elastic.out(1, 1)",
            stagger: 0.05,
        }, "-=50%")
    }

    if (controls) {
        tl.from(controls, {
            opacity: 0
        })
    }
}

export default () => {
    const els = Array.from(document.querySelectorAll('.basic-slider'))

    if (!els) return;

    els.forEach(setup)
}
