import { gsap } from "gsap";

function setup(container) {
    const contents = container.querySelectorAll('.product-content__container')

    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: container,
            start: 'top 75%',
            end: 'bottom top',
            // markers: true
        }
    })

    contents.forEach(content => {
        const inner = content.querySelectorAll('.product-content__main, .product-content__sidebar')

        tl.from(inner, {
            opacity: 0,
            translateY: '-10%',
            ease: "elastic.out(1, 1)",
            stagger: 0.05
        })
    })

}

export default () => {
    const els = Array.from(document.querySelectorAll('.product-content'))

    if (!els) return;

    els.forEach(setup)
}
