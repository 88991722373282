import submitAjaxForm from "../utils/submitAjaxForm"

const FILTER_BUTTON_ACTIVE_CLASS = 'filter-button__wrapper--active'

function findUp(targetElement, childSelector) {
    const parent = targetElement.parentNode;

    // Base case: If parent is null (reached the root element)
    if (!parent) {
        return null;
    }

    // Check if any child of the parent matches the childSelector
    const children = parent.querySelectorAll(childSelector);
    if (children.length > 0) {
        return children;
    }

    // Recursive case: Move up the DOM tree
    return findUp(parent, childSelector);
}

function toggleActiveFilters(input) {

    const available = document.querySelector('.listings__filter-available')
    let el = document.querySelector('.listings__filter-active')

    if (!el) {
        el = document.createElement('div')
        el.classList.add('listings__filter-active')
        available.insertAdjacentElement('afterend', el)
    }

    let inner = el.querySelector('.listings__filter-selected')

    if (!inner) {
        inner = document.createElement('div')
        inner.classList.add('listings__filter-selected')
        el.appendChild(inner)
    }

    let label = el.querySelector(`label[for="${input.getAttribute('id')}"]`)

    if (!label) {
        label = document.createElement('label')
        label.setAttribute('for', `${input.getAttribute('id')}`)
        label.classList.add('filter-button')
        label.classList.add('filter-button--active')
        label.innerHTML = input.parentNode.innerHTML

        inner.appendChild(label)
    } else {
        inner.removeChild(label)
    }
}

function setupModal(modal, selector) {

    const MODAL_ACTIVE_CLASS = 'listings__filter-modal--active'

    const modalTrigger = modal.parentElement.querySelector('.listings__filter-modal-trigger')
    const applyTrigger = modal.querySelector('.listings__filter-modal-apply')
    const clearTrigger = modal.querySelector('.listings__filter-modal-clear')
    const closeTrigger = modal.querySelector('.listings__filter-modal-close')

    const form = modal.querySelector('form')

    const inputs = Array.from(modal.querySelectorAll('input'))

    inputs.forEach(input => {
        input.addEventListener('change', (e) => {
            const parent = modal.parentNode
            const relatedInput = parent.querySelector(`.listings__actionbar input[id="${input.dataset.id}"]`)

            relatedInput.checked = input.checked
            const fake = new Event('change')
            relatedInput.dispatchEvent(fake)
            // toggleActiveFilters(input)
            // submitAjaxForm(form, selector)
        })
    })

    applyTrigger.addEventListener('click', (e) => {
        e.preventDefault() // Do nothing since our input watcher already did the submission
        modal.classList.remove(MODAL_ACTIVE_CLASS)
    })

    modalTrigger.addEventListener('click', () => {
        modal.classList.add(MODAL_ACTIVE_CLASS)
    })

    closeTrigger.addEventListener('click', (e) => {
        e.preventDefault()
        modal.classList.remove(MODAL_ACTIVE_CLASS)
    })
}

// Merges form data from form B to form A
function mergeFormData(formA, formB) {

    const formDataB = new FormData(formB);
    const formDataA = new FormData(formA);

    // console.log('A: ')
    // for (const [key, value] of formDataA.entries()) {
    //     console.log(`${key}=${value}`)
    // }
    //
    // console.log('B: ')
    // for (const [key, value] of formDataB.entries()) {
    //     console.log(`${key}=${value}`)
    // }

    for (const [key, value] of formDataA.entries()) {
        const el = formA.querySelector(`[name="${key}"][value="${value}"]`)
        if (formDataB[key] === undefined && el) {
            el.remove()
        }
    }

    for (const [key, value] of formDataB.entries()) {

        if (formA.querySelector(`[name="${key}"][value="${value}"]`) === null) {
            const input = document.createElement('input');
            input.type = 'hidden';
            input.name = key;
            input.value = value;
            formA.appendChild(input);
        }
    }

    return formA;
}

function setupProductOverview(filter) {

    const selector = '.product-listings__container'

    const modal = findUp(filter, '.listings__filter-modal')

    if (!modal) {
        console.error('Something went wrong!')
    } else {
        setupModal(modal[0], selector)
    }

    const form = filter.querySelector('form')
    const wrappers = Array.from(filter.querySelectorAll('.filter-button__wrapper'))

    const searchForm = filter.parentNode.querySelector('.search-field__holder form')

    searchForm.addEventListener('submit', (e) => {
        e.preventDefault()
        mergeFormData(searchForm, form)
        submitAjaxForm(searchForm, selector)
    })

    wrappers.forEach(wrapper => {
        const button = wrapper.querySelector('.filter-button__trigger')
        const options = wrapper.querySelector('.filter-button__options')
        const inputs = Array.from(wrapper.querySelectorAll('input'))

        inputs.forEach(input => {
            input.addEventListener('change', (e) => {
                toggleActiveFilters(input)
                mergeFormData(searchForm, form)
                submitAjaxForm(searchForm, selector)
            })
        })

        button.addEventListener('click', (e) => {
            e.preventDefault()
            wrapper.classList.toggle(FILTER_BUTTON_ACTIVE_CLASS)
        })

        document.addEventListener('click', (e) => {
            if (!button.contains(e.target) &&
                !options.contains(e.target) &&
                wrapper.classList.contains(FILTER_BUTTON_ACTIVE_CLASS)
            ) {
                wrapper.classList.remove(FILTER_BUTTON_ACTIVE_CLASS)
            }
        })
    })
}

function productOverviewFilters() {

    const filters = Array.from(document.querySelectorAll('.ProductOverviewPage .listings__filter'))

    if (!filters) return

    filters.forEach(setupProductOverview)
}

productOverviewFilters();

function setupArticleFilter(filter) {

    const selector = '.listings'

    const modal = findUp(filter, '.listings__filter-modal')

    if (!modal) {
        console.error('Something went wrong!')
    } else {
        setupModal(modal[0], selector)
    }

    const form = filter.querySelector('form')
    const wrappers = Array.from(filter.querySelectorAll('.filter-button__wrapper'))

    const searchForm = filter.parentNode.querySelector('.search-field__holder form')


    searchForm.addEventListener('submit', (e) => {
        e.preventDefault()
        mergeFormData(searchForm, form)
        submitAjaxForm(searchForm, selector, () => {
            newsFilters()
        })
    })

    wrappers.forEach(wrapper => {
        const button = wrapper.querySelector('.filter-button__trigger')
        const options = wrapper.querySelector('.filter-button__options')
        const inputs = Array.from(wrapper.querySelectorAll('input'))

        inputs.forEach(input => {
            input.addEventListener('change', (e) => {
                toggleActiveFilters(input)
                mergeFormData(searchForm, form)
                submitAjaxForm(searchForm, selector, () => {
                    newsFilters()
                })
            })
        })

        button.addEventListener('click', (e) => {
            e.preventDefault()
            wrapper.classList.toggle(FILTER_BUTTON_ACTIVE_CLASS)
        })

        document.addEventListener('click', (e) => {
            if (!button.contains(e.target) &&
                !options.contains(e.target) &&
                wrapper.classList.contains(FILTER_BUTTON_ACTIVE_CLASS)
            ) {
                wrapper.classList.remove(FILTER_BUTTON_ACTIVE_CLASS)
            }
        })
    })
}

function newsFilters() {

    const filters = Array.from(document.querySelectorAll('.ArticleHolderPage .listings__filter'))

    if (!filters) return

    filters.forEach(setupArticleFilter)
}

newsFilters()

function setupProjectFilter(filter) {

    const selector = '.listings'
    const modal = findUp(filter, '.listings__filter-modal')

    if (!modal) {
        console.error('Something went wrong!')
    } else {
        setupModal(modal[0], selector)
    }

    const form = filter.querySelector('form')
    const wrappers = Array.from(filter.querySelectorAll('.filter-button__wrapper'))

    const searchForm = filter.parentNode.querySelector('.search-field__holder form')

    searchForm.addEventListener('submit', (e) => {
        e.preventDefault()
        mergeFormData(searchForm, form)
        submitAjaxForm(searchForm, selector, () => {
            projectFilters()
        })
    })

    wrappers.forEach(wrapper => {
        const button = wrapper.querySelector('.filter-button__trigger')
        const options = wrapper.querySelector('.filter-button__options')
        const inputs = Array.from(wrapper.querySelectorAll('input'))

        inputs.forEach(input => {
            input.addEventListener('change', (e) => {
                toggleActiveFilters(input)
                mergeFormData(searchForm, form)
                submitAjaxForm(searchForm, selector, () => {
                    projectFilters()
                })
            })
        })

        button.addEventListener('click', (e) => {
            e.preventDefault()
            wrapper.classList.toggle(FILTER_BUTTON_ACTIVE_CLASS)
        })

        document.addEventListener('click', (e) => {
            if (!button.contains(e.target) &&
                !options.contains(e.target) &&
                wrapper.classList.contains(FILTER_BUTTON_ACTIVE_CLASS)
            ) {
                wrapper.classList.remove(FILTER_BUTTON_ACTIVE_CLASS)
            }
        })
    })
}

function projectFilters() {

    const filters = Array.from(document.querySelectorAll('.ProjectHolderPage .listings__filter'))

    if (!filters) return

    filters.forEach(setupProjectFilter)
}

projectFilters()
