
function setup(trigger) {

    const header = document.querySelector('header')
    const id = trigger.dataset.megaTrigger
    const menu = document.querySelector(`[data-mega="${id}"]`)
    const menus = Array.from(document.querySelectorAll(`[data-mega]`))

    let wait = 300
    let timer = null
    let activeTrigger = null

    trigger.addEventListener('mouseenter', () => {
        clearTimeout(timer)
        activeTrigger = id

        // console.log('Entering trigger: ', activeTrigger)

        menus.forEach(m => {
            if (m.dataset != menu.dataset) {
                m.classList.remove('mega-menu--active')
            }
        })

        header.classList.add('header--mega-active')
        menu.classList.add('mega-menu--active')
    })

    trigger.addEventListener('mouseleave', () => {
        clearTimeout(timer)

        // console.log('Leaving trigger: ', activeTrigger)

        timer = setTimeout(() => {
            // console.log('Starting leave timeout for trigger: ', activeTrigger)

            menu.classList.remove('mega-menu--active')

            const found = menus.find(menu => {
                return menu.classList.contains('mega-menu--active')
            })

            if (!found) {
                header.classList.remove('header--mega-active')
                activeTrigger = null
            }

        }, wait)
    })

    menu.addEventListener('mouseenter', () => {
        clearTimeout(timer)
        activeTrigger = id
        // console.log('Entering Menu: ', activeTrigger)
    })

    menu.addEventListener('mouseleave', () => {
        clearTimeout(timer)

        timer = setTimeout(() => {
            menu.classList.remove('mega-menu--active')

            const found = menus.find(menu => {
                return menu.classList.contains('mega-menu--active')
            })

            if (!found) {
                header.classList.remove('header--mega-active')
                activeTrigger = null
            }
        }, wait)
    })
}


function init() {

    const triggers = Array.from(document.querySelectorAll('[data-mega-trigger]'))

    triggers.forEach(setup)
}

init()
