import { tns } from "tiny-slider";

function setup(slider) {
    const container =  slider.querySelector('.full-slider__container')
    const controlsContainer =  slider.querySelector('.full-slider__controls')
    const navContainer = slider.querySelector('.full-slider__nav')

    tns({
        container,
        controlsContainer,
        navContainer
    })
}

function init() {

    const sliders = Array.from(document.querySelectorAll('.full-slider'))

    if (!sliders.length) return;

    sliders.forEach(setup)
}

init();
