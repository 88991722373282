
export default function attachQueryParamsToForm(form, queryParams) {
    const formData = new FormData();

    // Iterate through each key-value pair in the query parameters
    for (const [key, value] of Object.entries(queryParams)) {
        // Append each key-value pair to the FormData object
        formData.append(key, value);
    }

    // Iterate through each FormData entry and append it to the form
    for (const [key, value] of formData.entries()) {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = key;
        input.value = value;
        form.appendChild(input);
    }
}
