import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";

function setup(container) {
    let title = Array.from(container.querySelectorAll('.prefix-title h1'))
    const content = container.querySelector('.hero-content__details')
    const image = container.querySelector('.hero-content__image')

    if (title.length) {
        title = title.map(item => {
            gsap.set(item, { overflow: 'hidden' })
            item.innerHTML = `<span>${item.innerHTML}</span>`
            return item.querySelector('span')
        })
    }

    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: container,
            start: 'top 75%',
            end: 'bottom top',
            // markers: true
        }
    })

    if (title.length) {
        tl.from(title, {
            display: 'block',
            skewY: '4deg',
            translateY: '60px',
            transformOrigin: 'top left',
            delay: 0.5,
            stagger: 0.1,
            opacity: 0,
            ease: "elastic.out(1, 1)"
        })
    }

    tl.from(content, {
        opacity: 0
    }, '-=50%')

    tl.from(image, {
        opacity: 0,
        translateX: '10px'
    }, '<')

    const contentArea = container.parentNode.querySelector('.hero-content-area')
    const tl2 = gsap.timeline({
        scrollTrigger: {
            trigger: contentArea,
            start: 'top 75%',
            end: 'bottom top',
        }
    })

    tl2.from(contentArea, {
        opacity: 0,
        translateY: '20px'
    })

}

export default () => {
    const els = Array.from(document.querySelectorAll('.hero-content'))

    if (!els) return;

    els.forEach(setup)
}
