import { gsap } from "gsap";

function setup(container) {
    let title = Array.from(container.querySelectorAll('.product-section__title span'))
    const player = container.querySelectorAll('.player')
    const items = container.querySelectorAll('.playlist')

    if (title.length) {
        title = title.map(item => {
            gsap.set(item, { overflow: 'hidden' })
            item.innerHTML = `<span>${item.innerHTML}</span>`
            return item.querySelector('span')
        })
    }

    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: container,
            start: 'top 75%',
            end: 'bottom top',
            // markers: true
        }
    })

    if (title.length) {
        tl.from(title, {
            display: 'block',
            skewY: '4deg',
            translateY: '60px',
            transformOrigin: 'top left',
            delay: 0.5,
            stagger: 0.1,
            opacity: 0,
            ease: "elastic.out(1, 1)"
        })
    }

    if (player) {
        tl.from(player, {
            opacity: 0,
            translateY: '10px',
            ease: "elastic.out(1, 1)"
        }, "-=50%")
    }

    if (items) {

        tl.from(items, {
            opacity: 0,
            translateY: '10px',
            ease: "elastic.out(1, 1)",
            stagger: 0.05
        }, "-=50%")
    }
}

export default () => {
    const els = Array.from(document.querySelectorAll('.video-player'))

    if (!els) return;

    els.forEach(setup)
}
