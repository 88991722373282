function setup(category, index) {

    const wait = 500

    const categories = Array.from(document.querySelectorAll('.mega-products__category-products'))
    const categoryProducts = Array.from(document.querySelectorAll('.mega-products__category-products'))[index]
    const products = Array.from(categoryProducts.querySelectorAll('a'))
    const productItemContainer = Array.from(document.querySelectorAll('.mega-products__product-item-category'))[index]

    let timer = null

    category.addEventListener('mouseenter', () => {
        categories.forEach(cat => {
            if (cat.dataset !== category.dataset) {
                cat.classList.remove('mega-item--active')
            }
        })
        categoryProducts.classList.add('mega-item--active')
        productItemContainer.classList.add('mega-item--active')
    })

    // category.addEventListener('mouseleave', () => {
    //     timer = setTimeout(() => {
    //         categoryProducts.classList.remove('mega-item--active')
    //         productItemContainer.classList.remove('mega-item--active')
    //     }, wait)
    // })

    products.forEach((product, i) => {
        const productData = Array.from(Array.from(document.querySelectorAll('.mega-products__product-single > div'))[index].children)[i]

        product.addEventListener('mouseenter', () => {
            clearTimeout(timer)
            productData.classList.add('mega-item--active')
        })

        product.addEventListener('mouseleave', () => {
            productData.classList.remove('mega-item--active')
        })
    })
}

function init() {

    // const header = document.querySelector('header')
    // header.classList.add('header--mega-active')
    // const menus = Array.from(document.querySelectorAll(`[data-mega]`))
    // menus[0].classList.add('mega-menu--active')

    const categories = Array.from(document.querySelectorAll('.mega-products__categories a'))
    categories.forEach(setup)
}

init()
