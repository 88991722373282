import { gsap } from "gsap";

function setup(container) {
    const title = container.querySelectorAll('.product-section__title')

    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: container,
            start: 'top 75%',
            end: 'bottom top',
            // markers: true
        }
    })

    tl.from(title, {
        opacity: 0,
        translateY: '-10%',
        ease: "elastic.out(1, 1)",
    })

    const cards = container.querySelectorAll('.benefits__card')

    tl.from(cards, {
        opacity: 0,
        translateY: '10%',
        ease: "elastic.out(1, 1)",
        stagger: 0.05
    })

}

export default () => {
    const els = Array.from(document.querySelectorAll('.benefits'))

    if (!els) return;

    els.forEach(setup)
}
