import { gsap } from "gsap";

function setup(container) {
    const title = container.querySelectorAll('.product-section__title')

    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: container,
            start: 'top 75%',
            end: 'bottom top',
            // markers: true
        }
    })

    tl.from(title, {
        opacity: 0,
        translateY: '-10%',
        ease: "elastic.out(1, 1)",
    })

    const image = container.querySelectorAll('.content-checklist__image')
    const content = container.querySelectorAll('.content-checklist__content')

    tl.from(image, {
        opacity: 0,
        translateX: '-10%',
        ease: "elastic.out(1, 1)",
    })
    tl.from(content, {
        opacity: 0,
        translateX: '10%',
        ease: "elastic.out(1, 1)",
    }, "<")

    const blocks = container.querySelectorAll('.split-content')

    tl.from(blocks, {
        opacity: 0,
        translateY: '10%',
        ease: "elastic.out(1, 1)",
        stagger: 0.05
    }, "<")

}

export default () => {
    const els = Array.from(document.querySelectorAll('.applications'))

    if (!els) return;

    els.forEach(setup)
}
