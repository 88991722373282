
export default function getURLParams() {
    const url = window.location.href;

    // Create a new URLSearchParams object with the URL search parameters
    const searchParams = new URLSearchParams(new URL(url).search);

    // Loop through all the search parameters and store them in an object
    const params = {};
    for (const [key, value] of searchParams.entries()) {
        params[key] = value;
    }

    return params
}
