import { tns } from 'tiny-slider'

function next(sliders) {
    sliders.forEach(slider => {
        slider.goTo('next')
    })
}

function prev(sliders) {
    sliders.forEach(slider => {
        slider.goTo('prev')
    })
}

function setup(wrapper) {

    const testimonialsElement = wrapper.querySelector('.testimonials__testimonial-content')
    const navContainer = wrapper.querySelector('.testimonials__nav')
    const logoElements = Array.from(wrapper.querySelectorAll('.testimonials__testimonial-logo-wrapper'))

    const main = tns({
        container: testimonialsElement,
        navContainer: navContainer,
        items: 1,
        controls: false,
        mouseDrag: true,
    })

    // We are assuming 5 logos otherwise this logic needs to be changed
    const logos = logoElements.map((element, i) => {
        // We start with
        // [0][1][2][3][4]
        //
        // We want
        // [3][4][0][1][2]
        //
        // We start with
        // [0][1][2][3][4][5]
        //
        // We want
        // [4][5][0][1][2] // Three will be off screen

        // Start index should be 0 for the center item
        // 1 for the right of center and 2 for the far right
        // then we can say lenght - 1 for far left and length - 2 for left center

        const startIndex = (i <= 1) ? logoElements.length - (2 - i) : i - 2;

        return tns({
            container: element,
            items: 1,
            controls: false,
            nav: false,
            mouseDrag: false,
            startIndex
        })
    })

    let oldIndex = 1;

    let run = true

    main.events.on('indexChanged', () => {

        const { displayIndex } = main.getInfo()
        // This runs twice? no idea why
        if (oldIndex === displayIndex) return

        console.log('old: ', oldIndex)
        console.log('new: ', displayIndex)

        if (
            (oldIndex + 1 === displayIndex) ||
            (oldIndex === logos.length && displayIndex === 1)
        ) {
            next(logos)
        }

        if (
            (oldIndex - 1 === displayIndex) ||
            (oldIndex === 1 && displayIndex === logos.length)
        ) {
            prev(logos)
        }

        oldIndex = displayIndex
    })

    logos.forEach(logo => {
        const { container } = logo.getInfo()

        container.addEventListener('click', () => {
            const { displayIndex } = logo.getInfo()
            // console.log(displayIndex)
            main.goTo(displayIndex - 1)
        })
    })
}


function init() {

    const testimonials = Array.from(document.querySelectorAll('.testimonials'))

    if (!testimonials.length) return;

    testimonials.forEach(setup)
}

init();

